<template>
  <div class=container>
      <div class=table>
<h1> Overflow Issues </h1>

Note that overflow is a problem in some of the
measurements. Recorded images with a significant number
of saturated pixel values 
result in inaccurate brdf measurements.
As a guide to the confidence of each measurement,
the files <a href="https://cave.cs.columbia.edu/old/software/curet/data/rover.dat"> rover.dat</a>,
 <a href="https://cave.cs.columbia.edu/old/software/curet/data/gover.dat"> gover.dat </a>
and
 <a href="https://cave.cs.columbia.edu/old/software/curet/data/bover.dat">  bover.dat </a>
to give the overflow percentage for the
red, green and blue measurements respectively.
These overflow files have the following
format:
<pre>1 overflow1 overflow2 . . . overflow205
2 overflow1 overflow2 . . . overflow205
.
.
.
61 overflow1 overflow2 . . . overflow205
</pre>
where the first number in each
row is the sample number and the subsequent
numbers 
are the overflow percentage (fraction
of pixel values greater than 240) for
each of the 205 BRDF measurements associated with that sample.



<hr>
<router-link to="/repository/CURET/brdfm">  [Return to BRDF Measurements] </router-link>

      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>